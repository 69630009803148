(function ($K) {
  $K.add('module', 'sendshortlist', {
    init: function (app, context) {
      this.app = app;
      this.context = context;

      // modal id
      this.modalId =
        'sendShortlistModal_' + performance.now() * Math.pow(10, 15);

      // default params
      var defaultParams = {
        authenticated: false,
      };

      this.params = context.getParams(defaultParams);
      this.checkParams();

      // define service
      this.modal = app.modal;

      // build modal
      this.modalBody = this.buildModal();
    },

    checkParams: function () {
      if (this.params.authenticated === '') {
        this.params.authenticated = false;
      }
    },

    buildModal: function () {
      var modalHtml = ``;

      if (!this.params.authenticated) {
        modalHtml = `<div id="${this.modalId}" class="send-shortlist-modal-body is-hidden">
  
                <div class="is-container-row is-inset-x-16 is-inset-y-32 is-inset-x-8-mobile is-inset-y-16-mobile">
                  <h1 class="is-text-center text-purple">Please sign up or login now!</h1>
                </div>
  
                <div class="is-container-row is-inset-x-16 is-inset-y-32 is-inset-x-8-mobile is-inset-y-16-mobile">
                  <div class="is-center">
                    <a href="/carer/register" class="button is-big" onclick="FAB.trackJoinClick('shortlist');">SIGN UP</a>
                  </div>
                  <div class="is-center">
                    <a href="/account/logon" class="button is-big" onclick="FAB.trackLoginClick('shortlist');">LOGIN</a>
                  </div>
                </div>
  
              </div>`;
      }

      return modalHtml;
    },

    onclick: function (e, element, type) {
      if (type === 'sendshortlist') {
        // show modal
        this.showModal();
      }
    },

    showModal: function () {
      $K.dom('.send-shortlist-modal-body').remove();
      $K.dom('body').append(this.modalBody);
      if (this.params.authenticated == false) {
        this.modal.open({
          target: `#${this.modalId}`,
        });
      }
    },

    onmessage: {
      modal: {
        opened: function (sender, $modal, $form) {},
      },
    },
  });
})(Kube);
