import './kube.min.css';
import './main.css';
import './admin.css';
import './kube.min.js';
import './theme.css';
import './dashboard.css';
import './sendshortlist.js';
import './job_details.css';
import './carer_details.css';
import './job_info_block.css';
import './carer_info_block.css';
import './new_design.css';
import './layout.css';
import './home.css';
import './message.css';
import './typeahead_new.css';
import './date_block.css';

import './microtip.css';
import './microtip-custom.css';

import './carer_new_block.css';
import './edit_page.css';
import './custom_checkbox.css';
import './carer_edit.css';
import './custom_radio.css';
import './message_thread.css';
import './payment.css';
import './search_babysitter.css';
import './spinner.css';

import './alert.css';

import './modernizr-webp';

$K.dom(window).on('load', $K.init);

window.FAB = {
  setRole: function (role) {
    Cookies.set('role', role);
  },
  auth0Login: function (role, connection) {
    // Set a cookie - will that work?
    Cookies.set('role', role);
    webAuth.authorize({ connection: connection });
  },
  setProduct: function (product) {
    Cookies.set('product', product);
  },
  docReady: function (fn) {
    // see if DOM is already available
    if (
      document.readyState === 'complete' ||
      document.readyState === 'interactive'
    ) {
      // call on next available tick
      setTimeout(fn, 1);
    } else {
      document.addEventListener('fabLoadComplete', fn);
    }
  },
  showMessage: function (params) {
    var defaults = {
      position: 'centered',
      type: 'is-success',
      delay: 4,
    };
    $K.api('message.show', {
      ...defaults,
      ...params,
    });
  },
  showSuccess: function (message) {
    this.showMessage({ message });
  },
  showInfo: function (message) {
    this.showMessage({ message, type: 'is-focus' });
  },
  showError: function (message) {
    this.showMessage({ message, type: 'is-error' });
  },
  fireGtmEvent: function (event) {
    if (window.dataLayer) {
      window.dataLayer.push(event);
    }
  },
  trackJoinClick: function (source) {
    this.fireGtmEvent({
      event: 'join_click',
      source,
    });
  },
  trackLoginClick: function (source) {
    this.fireGtmEvent({
      event: 'login_click',
      source,
    });
  },
  showOverlays: function (divIds) {
    FAB.body_overflow = document.querySelector('body').style.overflow || 'auto';
    document.querySelector('body').style.overflow = 'hidden';
    FAB.scrollPosition = window.pageYOffset;
    var classesToCheck = ['is-hidden-mobile', 'is-shown-mobile', 'is-hidden'];
    for (var j = 0; j < divIds.length; j++) {
      var divId = divIds[j];
      for (var i = 0; i < classesToCheck.length; i++) {
        var div = $('#' + divId);
        var classToCheck = classesToCheck[i];
        if (div.hasClass(classToCheck)) {
          if (!FAB.hasOwnProperty('previousClasses')) {
            FAB.previousClasses = {};
          }
          FAB.previousClasses[divId] = classToCheck;
          div.removeClass(classToCheck);
        }
      }
    }
    document.querySelector('body').style.position = 'fixed';
    setTimeout(function () {
      document.querySelector('body').style.position = 'static';
    }, 100);
  },
  hideOverlays: function (divIds) {
    document.querySelector('body').style.overflow = FAB.body_overflow;
    document.querySelector('body').style.position = 'static';
    window.scrollTo(0, FAB.scrollPosition);
    for (var i = 0; i < divIds.length; i++) {
      var divId = divIds[i];
      $('#' + divId).addClass(FAB.previousClasses[divId]);
    }
    return false;
  },
};
